import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../API/Api";
import Layout from "../Layout";
import "./Coupon.css";
import BgLayout from "../sharecomponent/BgLayout";
import ShareTable from "../sharecomponent/ShareTable";
import { loadWeb3 } from "../../apis/api";

const columns = [
  {
    dataField: "Number",
    text: "So.No",
    sort: false,
  },
  // {
  //   dataField: "fromUserid",
  //   text: "From Id",
  //   sort: false,
  // },
  {
    dataField: "id",
    text: "Receiver Id",
    sort: false,
  },
  // {
  //     dataField: "coupon",
  //     text: "Coupon No.",
  //     sort: false,
  // },

  {
    dataField: "amount",
    text: "Amount",
    sort: false,
  },
  {
    dataField: "coupon",
    text: "Point",
    sort: false,
  },
  {
    dataField: "DateTime",
    text: "Date & Time",
    sort: false,
  },
];

function Convert_Coupon() {
  const user = useSelector((state) => state.UserAuth.userId);
  const [coupon, setCoupon] = useState("");
  const [userName, setUserName] = useState("");
  const [dataArray, setdataArray] = useState([]);
  const [amount, setamount] = useState("");
  const [topid, setTopId] = useState("");
  const [larbalance, setLarBalance] = useState("");

  const CouponGenerate = async () => {
    try {

      const acc = await loadWeb3();
      let res = await API.post(`/getDashboardValues_New?id=${user}`)
     console.log('betawallet', res.data.data[0])

      res = res.data.data[0]

      if (res.address == acc) {
      let responce = await API?.post("/AddCoupanFundToGameWallet", {
        uid: user,
        amount: amount,
      });
      responce = responce?.data?.data;
      console.log("Coupon", responce);
      if (responce == "Fund Added Successfully.") {
        alert("Fund Added Successfully.");
      } else if (responce == "Invalid ToUserID !!")
        alert(responce);
      else {
        alert(responce);
      }
    }else
    {
      alert ("Metamask Address is mismatch !!")
    }
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  const dashboard_coupon = async () => {
    try {
      let responce = await API.post(`/getDashboardValues_New?id=${user}`);
      responce = responce.data.data;
      setLarBalance(responce[0].NetCouponBalance);
      // setUserName(responce[0].UserName);
      console.log("responceresponce====>", responce);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  const Transfer_coupon = async () => {
    try {
      let responce = await API?.post("/GetCouponDetailHistory", {
        uid: user,
        type: "User",
      });
      responce = responce.data.data[0];
      setCoupon(responce[0].coupon);
      setUserName(responce[0].UserName);
      console.log("responceresponce", responce);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  const referral_API = async () => {
    try {
      let responce = await API?.get(
        `/AddCoupanFundToGameWalletHistory?id=${user}`
      );
      responce = responce.data.data;
      // setCoupon(responce[0].coupon);
      // setUserName(responce[0].UserName);
      console.log("responceresponce1", responce);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item.row,
          //UserName: item?.UserName,
          id: item?.uid,
          amount: item?.amount,
          fromUserid: item?.fromuid,
          coupon: item?.point,
          // Txn: <><a href={`https://bscscan.com/tx/${item?.traxn}`} target="_blank" className='text-white'>View Txn</a></>,
          DateTime: item.dd,
        });
      });
      console.log("responce", arr);

      setdataArray(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };



  useEffect(() => {
    referral_API();
    Transfer_coupon();
    dashboard_coupon()
  }, []);

  return (
    <div>
      <Layout>
        <BgLayout>
        <div className="LARToken_Header">
          <h6>Coupon Convert To Game Wallet</h6>
        </div>
          <div className="Share_tableMain">
            <div>
              <div className="col-md-4 coupn_lar lar_inputWrper">
              <label htmlFor="transfer">Available Coupon Balance</label>
                <input
                  type="number"
                  name="transfer"
                  readonly=""
                  placeholder={`$ ${larbalance}`}
                
                />
              
                {/* <br /> */}
                <label htmlFor="usdamount">Enter Coupon Amount</label>
                <input
                  type="number"
                  placeholder="Enter Fund"
                  name="usdamount"
                  onChange={(e) => setamount(e.target.value)}
                />
                  <label htmlFor="transfer">Total Point</label>
                <input
                  type="number"
                  placeholder="Enter Transfer Id"
                  name="transfer"
                  value={amount*90}
                  // onChange={(e) => setTopId(e.target.value)}
                  readOnly
                />
              </div>
              {/* <br /> */}
              <button
                className="btn btn-primary  coupon"
                onClick={() => CouponGenerate()}
              >
                Transfer Fund
              </button>
            </div>
          </div>
          <div className="BgLayout_Header">
            <h6>Coupon Convert History</h6>
          </div>
          <div className="Share_tableMain">
            <ShareTable columns={columns} Data={dataArray} />
          </div>
        </BgLayout>
      </Layout>
    </div>
  );
}

export default Convert_Coupon;
