import React from "react";

const BgLayout = (props) => {
  return (
    <>
      <div className="BgLayoutMain contest_data">{props.children}</div>
       
    </>
  );
};

export default BgLayout;
