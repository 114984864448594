import React from "react";
import { Layout } from "../components";
import "../assets/css/token.css";
import MainWalletROIWithdrawal from '../components/Withdrawal/MainWalletROIWithdrawal'


const MainWalletROI = () => {
  return (
    <>
      <Layout>
        <MainWalletROIWithdrawal />
      </Layout>
    </>
  );
};

export default MainWalletROI;
