import React from "react";
import { Layout, MainWalletNEWWithdrawal } from "../components";
import "../assets/css/token.css";


const MainWalletNEW = () => {
  return (
    <>
      <Layout>
        <MainWalletNEWWithdrawal />
      </Layout>
    </>
  );
};

export default MainWalletNEW;
