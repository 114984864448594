import React, { useState, useMemo, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Image } from "react-bootstrap";
import nftsImage from "../../assets/images/mint_nfts.png";
import hart from "../../assets/images/hart.png";
import share from "../../assets/images/share.png";
import scrollreveal from "scrollreveal";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from 'react-toastify'
import axios from "axios";
import {
  BUSD_Token,
  BUSD_Token_ABI,
  GLABA_NFT,
  GLABA_NFT_500,
  GLABA_NFT_1000,
  GLABA_NFT_2500,
  GLABA_NFT_5000,
  GLABA_NFT_ABI,
  GLABA_NFT_ABI_500,
  GLABA_NFT_ABI_1000,
  GLABA_NFT_ABI_2500,
  GLABA_NFT_ABI_5000

} from "../../utilies/constant";
import { loadWeb3 } from "../../apis/api";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { API } from "../../API/Api";
import Web3 from "web3";
import { setValue } from "../../redux/Slices/NFTSlice";

const CollectionNFT = () => {
  const singlenft = useSelector((state) => state.nft.SingleNFT);
  const Count = useSelector((state) => state.nft.count);
  const dispatch = useDispatch();


  const userDetail = useSelector((state) => state.nft.userDetail);
  const user = useSelector((state) => state.UserAuth.userId);
  const [netCoupon, setNetCoupon] = useState(0);
  const [Spinner, setSpinner] = useState(false);
  const [User_Api_Amount, setUser_Api_Amount] = useState(0);

  const [UserAddress, setUserAddress] = useState("");
  let navigate = useNavigate();

  const webSupply = new Web3("https://bsc-testnet.public.blastapi.io")
  const Tourace_Income_Bal = async () => {
    try {
      let res = await axios.post("https://taurusprotocol-1.nakshtech.info/getUserNetIncome", {
        uid: UserAddress,
      });
      res = res.data.data;
      console.log("getUserNetIncome==>", res.netincome);
      setUser_Api_Amount(res.netincome);
    } catch (e) {
      console.log("dashboard_Api_getUserNetIncome", e);
    }
  };


  // useEffect(() => {
  //   Tourace_Income_Bal();
  // }, []);

  const dashboard_Api = async () => {
    try {

      let res = await API.post(`/getDashboardValues_New?id=${user}`);
      console.log("response_time", res.data.data[0].address);

      res = res.data.data[0];
      setNetCoupon(res.NetCouponBalance * value);
      setUserAddress(res.address);
      let AddressUser = res.address;
      console.log("AddressUser", AddressUser);

      let Tauras_res = await axios.post("https://taurusprotocol-1.nakshtech.info/getUserNetIncome", {
        uid: AddressUser,
      });
      Tauras_res = Tauras_res.data.data;
      console.log("getUserNetIncome==>", Tauras_res.netincome);
      setUser_Api_Amount(Tauras_res.netincome);

    } catch (e) {
      console.log("dashboard_Api", e);
    }
  };
  const [value, setCount] = useState(1);

  useEffect(() => {
    dashboard_Api();

    const sr = scrollreveal(
      {
        origin: "left",
        distance: "10px",
        duration: 1000,
        reset: false,
      },
      [singlenft.minting_counter]
    );

    sr.reveal(
      `.CollectionMain .col-md-3,.CollectionMain .col-md-9
      `,
      {
        opacity: 0,
        interval: 200,
      }
    );
  }, []);

  const [selectedValue, setSelectedValue] = useState({
    name: "Mint with 100% USDT",
    price: "$0",
  });
  const [PriceArray, setPriceArray] = useState([
    {
      name: "Mint with 100% USDT",
      price: "$0",
    },
    {
      name: "50 % USDT And 50 % Coupon ",
      price: "$0",
    },
    {
      name: "90 % USDT And 10% Rapid Fund",
      price: "$0",
    }
  ]);

  const incrementCount = useMemo(

    () => () => setCount((prev) => prev + 1),
    [value]
  );
  const decrementCount = useMemo(() => {
    if (value === 0) {
      return;
    }
    return () => setCount((prev) => prev - 1);
  }, [value]);



  const handleChange = (e) => {
    e.preventDefault();
    let obj = JSON.parse(e.target.value);
    setSelectedValue(obj);
  };

  const mint = async () => {
    let acc = await loadWeb3();
    console.log("gdfgd", acc);
    if (acc == "No Wallet") {
      toast.error("No Wallet Connected");
    } else if (acc == "Wrong Network") {
      toast.error(
        "Wrong Newtwork please connect to Binance smart chain network"
      );
    } else {
      if(selectedValue?.name === PriceArray[0]?.name){
        MINTWITHBUSD();
      } else if(selectedValue?.name === PriceArray[1]?.name){
        BUSDAndIncome();
      }else if (selectedValue?.name === PriceArray[2]?.name) {
          MINTWITHUSDT90();
      }
    }
  };

  const MINTWITHBUSD = async () => {
    let acc = await loadWeb3();
    // console.log("ACC=",acc)
    if (acc == "No Wallet") {
      toast.error("No Wallet Connected");
    } else if (acc == "Wrong Network") {
      toast.error(
        "Wrong Newtwork please connect to Binance smart chain network"
      );
    } else {
      try {
        setSpinner(true);

        // let own_Address = userDetail.address
        let own_Address = true;

        console.log("res_Mint", own_Address == acc);
        if (UserAddress == "") {
          toast.error("Please Update Your Profile");
          navigate("/Profile");
          setSpinner(false);
        } else if (UserAddress == acc) {
          try {
            setSpinner(true);

            // setbtnFour('Please Wait While Processing')
            const web3 = window.web3;
            let approvetoken1 = new web3.eth.Contract(
              BUSD_Token_ABI,
              BUSD_Token
            );

            let nftContractOf;
            let increment_each_data;
            if (
              (singlenft.count == 100 && singlenft.minting_counter == 1) ||
              (singlenft.count == 200 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(GLABA_NFT_ABI, GLABA_NFT);
              increment_each_data = 0.00365946;
            } else if (
              (singlenft.count == 500 && singlenft.minting_counter == 1) ||
              (singlenft.count == 1000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_500,
                GLABA_NFT_500
              );
              increment_each_data = 0.0109232;
            } else if (
              (singlenft.count == 1000 && singlenft.minting_counter == 1) ||
              (singlenft.count == 2000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_1000,
                GLABA_NFT_1000
              );
              increment_each_data = 0.0182093;
            } else if (
              (singlenft.count == 2500 && singlenft.minting_counter == 1) ||
              (singlenft.count == 5000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_2500,
                GLABA_NFT_2500
              );
              increment_each_data = 0;
            } else if (
              (singlenft.count == 5000 && singlenft.minting_counter == 1) ||
              (singlenft.count == 10000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_5000,
                GLABA_NFT_5000
              );
              increment_each_data = 0.0910139;
            }


            if (value > 10) {
              // toast.error(`Maximum Limit is ${totalnft} `)
            } else {
              let token1 = await nftContractOf.methods.getmintPay1Price(Count).call();
              token1 = web3.utils.fromWei(token1);
              if (singlenft.minting_counter == 1) {
                token1 = await Number(token1);
                // console.log()
              } else if (singlenft.minting_counter == 2) {
                token1 = await Number(token1);
              }
              let totalMintingPriceToken_1 = web3.utils.toWei(token1.toString());
              console.log("token1", totalMintingPriceToken_1);
              
              const gasPrice = await window.web3.eth.getGasPrice();

              if (
                (singlenft.count == 100 && singlenft.minting_counter == 1) ||
                (singlenft.count == 200 && singlenft.minting_counter == 2)
              ) {
                await approvetoken1.methods
                  .approve(GLABA_NFT, totalMintingPriceToken_1)
                  .send({
                    from: acc,
                    gasPrice:gasPrice
                  });
              } else if (
                (singlenft.count == 500 && singlenft.minting_counter == 1) ||
                (singlenft.count == 1000 && singlenft.minting_counter == 2)
              ) {
                await approvetoken1.methods
                  .approve(GLABA_NFT_500, totalMintingPriceToken_1)
                  .send({
                    from: acc,
                    gasPrice:gasPrice
                  });
              } else if (
                (singlenft.count == 1000 && singlenft.minting_counter == 1) ||
                (singlenft.count == 2000 && singlenft.minting_counter == 2)
              ) {
                await approvetoken1.methods
                  .approve(GLABA_NFT_1000, totalMintingPriceToken_1)
                  .send({
                    from: acc,
                    gasPrice:gasPrice
                  });
              } else if (
                (singlenft.count == 2500 && singlenft.minting_counter == 1) ||
                (singlenft.count == 5000 && singlenft.minting_counter == 2)
              ) {
                await approvetoken1.methods
                  .approve(GLABA_NFT_2500, totalMintingPriceToken_1)
                  .send({
                    from: acc,
                    gasPrice:gasPrice
                  });
              } else if (
                (singlenft.count == 5000 && singlenft.minting_counter == 1) ||
                (singlenft.count == 10000 && singlenft.minting_counter == 2)
              ) {
                await approvetoken1.methods
                  .approve(GLABA_NFT_5000, totalMintingPriceToken_1)
                  .send({
                    from: acc,
                    gasPrice:gasPrice
                  });
              }

              toast.success("Approve Confirmed LAR Token");



              let hash = await nftContractOf.methods.mintPay1(Count).send({
                from: acc,
                gasPrice:gasPrice
              });
              // setbtnFour('Mint With BUSD')
              hash = hash.transactionHash;

              let usdAmount = singlenft.count;
              if (singlenft.count == "200" && singlenft.minting_counter == 2) {
                usdAmount = "100";
              } else if (
                singlenft.count == "1000" &&
                singlenft.minting_counter == 2
              ) {
                usdAmount = "500";
              } else if (
                singlenft.count == "2000" &&
                singlenft.minting_counter == 2
              ) {
                usdAmount = "1000";
              } else if (
                singlenft.count == "5000" &&
                singlenft.minting_counter == 2
              ) {
                usdAmount = "2500";
              } else if (
                singlenft.count == "10000" &&
                singlenft.minting_counter == 2
              ) {
                usdAmount = "5000";
              }

              let postapi = await axios.post(
                "https://nftxpress-1.nakshtech.info/activation",
                {
                  uid: user,
                  sid: "0",
                  transaction: hash,
                  amount: usdAmount,
                  useraddress: acc,
                  tokenamount: "0",
                  type: "Without Referral ID",
                  quantity: Count,
                  horseType: singlenft.minting_counter == 1 ? "SINGLE" : "DUAL",
                  MintType:selectedValue.name
                }
              );
              toast.success("Transaction Confirmed");
              setSpinner(false);

              console.log("postapi", postapi);
              toast.success(postapi.data.data);

            }
          } catch (e) {
            console.log("Error while minting ", e);
            toast.error("Transaction failed");
            setSpinner(false);
          }
        } else {
          toast.error("Wrong Metamask Address");
          setSpinner(false);
        }
      } catch (e) {
        console.log("Error ", e);
        setSpinner(false);
      }
    }
  };
  const MINTWITHUSDT90 = async () => {

    let acc = await loadWeb3();
    console.log("ACC=", acc)
    if (acc == "No Wallet") {
      toast.error("No Wallet Connected");
    } else if (acc == "Wrong Network") {
      toast.error(
        "Wrong Newtwork please connect to Binance smart chain network"
      );
    } else {
      try {
        setSpinner(true);

        // let own_Address = userDetail.address
        let own_Address = true;

        console.log("res_Mint", own_Address == acc);
        if (UserAddress == "") {
          toast.error("Please Update Your Profile");
          navigate("/Profile");
          setSpinner(false);
        } else if (UserAddress == acc) {
          try {
            setSpinner(true);

            // const priceWithoutUnit = selectedValue.price.split("USDT")[1];
            console.log(singlenft?.count,"nft")
            const response = await axios.post("https://nftxpress-1.nakshtech.info/Pro_activation_rapid", {
              uid: user,
              amount: singlenft.count
            });
            console.log(response,"rrrr")

            if (response.data.success && response.data.data === "0") {
              setSpinner(false);
            return toast.error("Insufficent Rapid Funds");
            }

            const web3 = window.web3;
            let approvetoken1 = new web3.eth.Contract(
              BUSD_Token_ABI,
              BUSD_Token
            );

            let nftContractOf;
            let increment_each_data;
            if (
              (singlenft.count == 100 && singlenft.minting_counter == 1) ||
              (singlenft.count == 200 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(GLABA_NFT_ABI, GLABA_NFT);
              increment_each_data = 0.00365946;
            } else if (
              (singlenft.count == 500 && singlenft.minting_counter == 1) ||
              (singlenft.count == 1000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_500,
                GLABA_NFT_500
              );
              increment_each_data = 0.0109232;
            } else if (
              (singlenft.count == 1000 && singlenft.minting_counter == 1) ||
              (singlenft.count == 2000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_1000,
                GLABA_NFT_1000
              );
              increment_each_data = 0.0182093;
            } else if (
              (singlenft.count == 2500 && singlenft.minting_counter == 1) ||
              (singlenft.count == 5000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_2500,
                GLABA_NFT_2500
              );
              increment_each_data = 0;
            } else if (
              (singlenft.count == 5000 && singlenft.minting_counter == 1) ||
              (singlenft.count == 10000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_5000,
                GLABA_NFT_5000
              );
              increment_each_data = 0.0910139;
            }


            if (value > 10) {
              // toast.error(`Maximum Limit is ${totalnft} `)
            } else {
              let token1 = await nftContractOf.methods.getmintPay2Price(Count).call();
                token1 = web3.utils.fromWei(token1);
                console.log(token1,"usdt 90")

                if (singlenft.minting_counter == 1 || singlenft.minting_counter == 2) {
                  token1 = (parseFloat(token1) + 1).toString();
                  console.log("Updated token1: " + token1);
                } else {
                  console.log("Token1 remains unchanged: " + token1);
                }

              let totalMintingPriceToken_1 = web3.utils.toWei(token1.toString());
             
              
              const gasPrice = await window.web3.eth.getGasPrice();
              if (
                (singlenft.count == 100 && singlenft.minting_counter == 1) ||
                (singlenft.count == 200 && singlenft.minting_counter == 2)
              ) {
                await approvetoken1.methods
                  .approve(GLABA_NFT, totalMintingPriceToken_1)
                  .send({
                    from: acc,
                    gasPrice:gasPrice
                  });
              } else if (
                (singlenft.count == 500 && singlenft.minting_counter == 1) ||
                (singlenft.count == 1000 && singlenft.minting_counter == 2)
              ) {
                await approvetoken1.methods
                  .approve(GLABA_NFT_500, totalMintingPriceToken_1)
                  .send({
                    from: acc,
                    gasPrice:gasPrice
                  });
              } else if (
                (singlenft.count == 1000 && singlenft.minting_counter == 1) ||
                (singlenft.count == 2000 && singlenft.minting_counter == 2)
              ) {
                await approvetoken1.methods
                  .approve(GLABA_NFT_1000, totalMintingPriceToken_1)
                  .send({
                    from: acc,
                    gasPrice:gasPrice
                  });
              } else if (
                (singlenft.count == 2500 && singlenft.minting_counter == 1) ||
                (singlenft.count == 5000 && singlenft.minting_counter == 2)
              ) {
                await approvetoken1.methods
                  .approve(GLABA_NFT_2500, totalMintingPriceToken_1)
                  .send({
                    from: acc,
                    gasPrice:gasPrice
                  });
              } else if (
                (singlenft.count == 5000 && singlenft.minting_counter == 1) ||
                (singlenft.count == 10000 && singlenft.minting_counter == 2)
              ) {
                await approvetoken1.methods
                  .approve(GLABA_NFT_5000, totalMintingPriceToken_1)
                  .send({
                    from: acc,
                    gasPrice:gasPrice
                  });
              }

              toast.success("Approve Confirmed USDT Token");

              let hash = await nftContractOf.methods.mintPay2(Count).send({
                from: acc,
                gasPrice:gasPrice
              });
              hash = hash.transactionHash;

              let usdAmount = singlenft.count;
              if (singlenft.count == "200" && singlenft.minting_counter == 2) {
                usdAmount = "100";
              } else if (
                singlenft.count == "1000" &&
                singlenft.minting_counter == 2
              ) {
                usdAmount = "500";
              } else if (
                singlenft.count == "2000" &&
                singlenft.minting_counter == 2
              ) {
                usdAmount = "1000";
              } else if (
                singlenft.count == "5000" &&
                singlenft.minting_counter == 2
              ) {
                usdAmount = "2500";
              } else if (
                singlenft.count == "10000" &&
                singlenft.minting_counter == 2
              ) {
                usdAmount = "5000";
              }

              let postapi = await axios.post(
                "https://nftxpress-1.nakshtech.info/activation",
                {
                  uid: user,
                  sid: "0",
                  transaction: hash,
                  amount: usdAmount,
                  useraddress: acc,
                  tokenamount: "0",
                  type: "Without Referral ID",
                  quantity: Count,
                  horseType: singlenft.minting_counter == 1 ? "SINGLE" : "DUAL",
                  MintType:selectedValue.name
                }
              );
              toast.success("Transaction Confirmed");
              setSpinner(false);

              console.log("postapi", postapi);
              toast.success(postapi.data.data);

            }
          } catch (e) {
            console.log("Error while minting ", e);
            toast.error("Transaction failed");
            setSpinner(false);
          }
        } else {
          toast.error("Wrong Metamask Address");
          setSpinner(false);
        }
      } catch (e) {
        console.log("Error ", e);
        setSpinner(false);
      }
    }
  };
 
  const BUSDAndIncome = async () => {

    let acc = await loadWeb3();
    if (acc == "No Wallet") {
      toast.error("No Wallet Connected");
    } else if (acc == "Wrong Network") {
      toast.error(
        "Wrong Newtwork please connect to Binance smart chain network"
      );
    } else {
      try {
        if (UserAddress == "") {
          toast.error("Please Update Your Profile");
          navigate("/Profile");
        } else if (UserAddress == acc) {
          try {
            setSpinner(true);
            const web3 = window.web3;
            let approvetoken1 = new web3.eth.Contract(
              BUSD_Token_ABI,
              BUSD_Token
            );

            let nftContractOf;
            let increment_each_data;
            if (
              (singlenft.count == 100 && singlenft.minting_counter == 1) ||
              (singlenft.count == 200 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(GLABA_NFT_ABI, GLABA_NFT);
              increment_each_data = 0.00365946;
            } else if (
              (singlenft.count == 500 && singlenft.minting_counter == 1) ||
              (singlenft.count == 1000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_500,
                GLABA_NFT_500
              );
              increment_each_data = 0.0109232;
            } else if (
              (singlenft.count == 1000 && singlenft.minting_counter == 1) ||
              (singlenft.count == 2000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_1000,
                GLABA_NFT_1000
              );
              increment_each_data = 0.0182093;
            } else if (
              (singlenft.count == 2500 && singlenft.minting_counter == 1) ||
              (singlenft.count == 5000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_2500,
                GLABA_NFT_2500
              );
              increment_each_data = 0.0182093;

            } else if (
              (singlenft.count == 5000 && singlenft.minting_counter == 1) ||
              (singlenft.count == 10000 && singlenft.minting_counter == 2)
            ) {
              nftContractOf = new web3.eth.Contract(
                GLABA_NFT_ABI_5000,
                GLABA_NFT_5000
              );
              increment_each_data = 0.0910139;
            }

            let token1 = await nftContractOf.methods
              .getmintPay3Price(Count)
              .call();
            token1 = web3.utils.fromWei(token1.toString());
            if (singlenft.minting_counter == 1) {
              token1 = Number(token1) + increment_each_data;
              console.log("Token!", token1);
            } else if (singlenft.minting_counter == 2) {
              token1 = Number(token1);
            }
            token1 = web3.utils.toWei(token1.toString());
            const gasPrice = await window.web3.eth.getGasPrice();
            
            if (
              (singlenft.count == 100 && singlenft.minting_counter == 1) ||
              (singlenft.count == 200 && singlenft.minting_counter == 2)
            ) {
              await approvetoken1.methods.approve(GLABA_NFT, token1).send({
                from: acc,
                gasPrice:gasPrice
              });
            } else if (
              (singlenft.count == 500 && singlenft.minting_counter == 1) ||
              (singlenft.count == 1000 && singlenft.minting_counter == 2)
            ) {
              await approvetoken1.methods.approve(GLABA_NFT_500, token1).send({
                from: acc,
                gasPrice:gasPrice
              });
            } else if (
              (singlenft.count == 1000 && singlenft.minting_counter == 1) ||
              (singlenft.count == 2000 && singlenft.minting_counter == 2)
            ) {
              await approvetoken1.methods.approve(GLABA_NFT_1000, token1).send({
                from: acc,
                gasPrice:gasPrice
              });
            } else if (
              (singlenft.count == 2500 && singlenft.minting_counter == 1) ||
              (singlenft.count == 5000 && singlenft.minting_counter == 2)
            ) {
              await approvetoken1.methods.approve(GLABA_NFT_2500, token1).send({
                from: acc,
                gasPrice:gasPrice
              });
            } else if (
              (singlenft.count == 5000 && singlenft.minting_counter == 1) ||
              (singlenft.count == 10000 && singlenft.minting_counter == 2)
            ) {
              await approvetoken1.methods.approve(GLABA_NFT_5000, token1).send({
                from: acc,
                gasPrice:gasPrice
              });
            }

            toast.success("Approve Confirmed BUSD Token");
            let hash = await nftContractOf.methods.mintPay3(Count).send({
              from: acc,
              gasPrice:gasPrice
            });

            hash = hash.transactionHash;

            let usdAmount = singlenft.count;
            if (singlenft.count == "200" && singlenft.minting_counter == 2) {
              usdAmount = "100";
            } else if (
              singlenft.count == "1000" &&
              singlenft.minting_counter == 2
            ) {
              usdAmount = "500";
            } else if (
              singlenft.count == "2000" &&
              singlenft.minting_counter == 2
            ) {
              usdAmount = "1000";
            } else if (
              singlenft.count == "5000" &&
              singlenft.minting_counter == 2
            ) {
              usdAmount = "2500";
            } else if (
              singlenft.count == "10000" &&
              singlenft.minting_counter == 2
            ) {
              usdAmount = "5000";
            }

            let postapi = await axios.post(
              "https://nftxpress-1.nakshtech.info/activation",
              {
                uid: user,
                sid: "0",
                transaction: hash,
                amount: usdAmount,
                useraddress: acc,
                tokenamount: "0",
                type: "Without Referral ID",
                quantity: Count,
                horseType: singlenft.minting_counter == 1 ? "SINGLE" : "DUAL",
                MintType:selectedValue.name
              }
            );
            toast.success("Transaction Confirmed");
            setSpinner(false);
            console.log("postapi", postapi);
            let res = await axios.post("https://taurusprotocol-1.nakshtech.info/save_nftxpress_user_mint", {
              uid: user,
              address: acc,
              packageamount: 100,
              txn: hash,
            });
            console.log("response_Cou pon_test==>", res.data.data);
            res = res.data.data;
           
            let CouponWallet = await API.post("/MintWithCouponWallet", {
              uid: user,
              amount: Count == 1 ? usdAmount : (usdAmount * Count),
              txn: hash,
            });
            console.log("response_Coupon==>", CouponWallet.data.data);
            CouponWallet = CouponWallet.data.data;
            if (CouponWallet == "Successfull") {
              toast.success("Minting Successful");
            } else {
              toast.error(CouponWallet);
            }
            // toast.success(postapi.data.data);
            window.location.reload(false);

          } catch (e) {
            console.log("Error while minting ", e);
            toast.error("Transaction failed");           
            setSpinner(false);
          }
        } else {
          toast.error("Wrong Metamask Address");
          setSpinner(false);
        }
      } catch (e) {
        console.log("Error ", e);
        setSpinner(false);
      }
    }
  };

  let webSupply1 = new Web3("https://bsc-mainnet.public.blastapi.io")
  const getVAlues = async () => {
    let acc = await loadWeb3();
    if (acc == "No Wallet") {
      toast.error("No Wallet Connected");
    } else if (acc == "Wrong Network") {
      toast.error(
        "Wrong Newtwork please connect to Binance smart chain network"
      );
    } else {
      try {
        const web3 = window.web3;
        let nftContractOf;
        let increment_each_data;

        if (
          (singlenft.count == 100 && singlenft.minting_counter == 1) ||
          (singlenft.count == 200 && singlenft.minting_counter == 2)
        ) {
          nftContractOf = new web3.eth.Contract(GLABA_NFT_ABI, GLABA_NFT);
          console.log(nftContractOf,"nft");
          increment_each_data = 0.00365946;
        } else if (
          (singlenft.count == 500 && singlenft.minting_counter == 1) ||
          (singlenft.count == 1000 && singlenft.minting_counter == 2)
        ) {
          nftContractOf = new web3.eth.Contract(
            GLABA_NFT_ABI_500,
            GLABA_NFT_500
          );
          increment_each_data = 0.0109232;
        } else if (
          (singlenft.count == 1000 && singlenft.minting_counter == 1) ||
          (singlenft.count == 2000 && singlenft.minting_counter == 2)
        ) {
          nftContractOf = new web3.eth.Contract(
            GLABA_NFT_ABI_1000,
            GLABA_NFT_1000
          );
          increment_each_data = 0.0182093;
        } else if (
          (singlenft.count == 2500 && singlenft.minting_counter == 1) ||
          (singlenft.count == 5000 && singlenft.minting_counter == 2)
        ) {
          nftContractOf = new web3.eth.Contract(
            GLABA_NFT_ABI_2500,
            GLABA_NFT_2500
          );
          increment_each_data = 0;
        } else if (
          (singlenft.count == 5000 && singlenft.minting_counter == 1) ||
          (singlenft.count == 10000 && singlenft.minting_counter == 2)
        ) {
          nftContractOf = new web3.eth.Contract(
            GLABA_NFT_ABI_5000,
            GLABA_NFT_5000
          );
          increment_each_data = 0.0910139;
        }
        let arr = [];

        if (singlenft.minting_counter == 1) {
          let mintingbnbPrice_Toke_2 = await nftContractOf.methods
            .getmintPay1Price(Count)
            .call();
          mintingbnbPrice_Toke_2 = web3.utils.fromWei(mintingbnbPrice_Toke_2);
          console.log("mintingbnbPrice_Toke_2", mintingbnbPrice_Toke_2);
          mintingbnbPrice_Toke_2 = mintingbnbPrice_Toke_2;
          arr.push({
            name: "Mint with 100% USDT",
            price: mintingbnbPrice_Toke_2,
          });
          setSelectedValue({ ...selectedValue, price: mintingbnbPrice_Toke_2 });
        } else if (singlenft.minting_counter == 2) {

          let mintingbnbPrice_Toke_2 = await nftContractOf.methods
            .getmintPay1Price(Count)
            .call();

          mintingbnbPrice_Toke_2 = web3.utils.fromWei(mintingbnbPrice_Toke_2);
          mintingbnbPrice_Toke_2 = mintingbnbPrice_Toke_2;
          arr.push({
            name: "Mint with 100% USDT",
            price: mintingbnbPrice_Toke_2,
          });
          setSelectedValue({ ...selectedValue, price: mintingbnbPrice_Toke_2 });
        }

      
        let mintingbnbPrice_Toke_3 = await nftContractOf.methods.getmintPay3Price(Count).call();
        console.log(mintingbnbPrice_Toke_3,"error")
        mintingbnbPrice_Toke_3 = web3.utils.fromWei(mintingbnbPrice_Toke_3);
        mintingbnbPrice_Toke_3 = Number(mintingbnbPrice_Toke_3);
        console.log("mintingbnbPrice_Toke_3",mintingbnbPrice_Toke_3)

        if (singlenft.minting_counter == 1) {
          mintingbnbPrice_Toke_3 = ` $${mintingbnbPrice_Toke_3} & Coupon ${netCoupon}`;
          arr.push({
            name: "50% USDT & 50% Coupon",
            price: mintingbnbPrice_Toke_3,
          });
        } else if (singlenft.minting_counter == 2) {
          mintingbnbPrice_Toke_3 = ` $${mintingbnbPrice_Toke_3} & Coupon ${netCoupon}`;
          arr.push({
            name: "50% USDT & 50% Coupon",
            price: mintingbnbPrice_Toke_3,
          });
        }

        let USDT90 = await nftContractOf.methods
        .getmintPay2Price(Count)
        .call();

        USDT90 = web3.utils.fromWei(USDT90)
        if (singlenft.minting_counter == 1) {
        let mintingPriceBNB = ` $${parseFloat(USDT90)} `;
        arr.push({
          name: "90% USDT & 10% Rapid Fund ",
          price: mintingPriceBNB,
        });
        } else if (singlenft.minting_counter == 2) {
        let mintingPriceBNB = `  $${parseFloat(USDT90)}`;

        arr.push({
          name: "90% USDT & 10% Rapid Fund",
          price: mintingPriceBNB,
        });

        }

        console.log("nftContractOf", nftContractOf);
        console.log("Count", Count);
        console.log("arr",arr)
        setPriceArray(arr);

      } catch (e) {
        console.log("Error while Get Vale ", e);
      }
    }
  };


  useEffect(() => {
    getVAlues();
  }, [singlenft, value, netCoupon, User_Api_Amount, Count]);

  return (
    <>
      <div className="NftMain CollectionMain">
        <Row>
          <Col xs={12} sm={12} md={3}>
            <div className="Nft_cardMain ">
              {singlenft && singlenft?.imageUrl ? (
                <Image
                  src={singlenft?.imageUrl}
                  alt="Image description"
                  fluid={true}
                  className="CollectionCrdImg"
                />
              ) : singlenft.videoUrl ? (
                <video controls className="CollectionCrdvid">
                  <source src={singlenft?.videoUrl} type="video/mp4" />
                </video>
              ) : (
                <Image
                  src={nftsImage}
                  alt="Image description"
                  fluid={true}
                  className="CollectionCrdImg"
                />
              )}

              {/* <h6>
                {singlenft && singlenft?.title ? singlenft?.title : "Ring"}
              </h6>
              <p>
                {singlenft && singlenft?.dec ? singlenft?.dec : "Ring #GLEBA"}
              </p> */}
              <div className="nftsPrice">
                <span>
                  {singlenft && singlenft?.price
                    ? `${singlenft?.title}`
                    : "$0.10"}
                </span>
                <span>
                  <Image src={hart} alt="Image description" fluid={true} />
                  {singlenft && singlenft?.count ? (
                    <>${singlenft?.count}</>
                  ) : (
                    "200"
                  )}
                </span>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={9}>
            <div className="NftArt">
              <div className="NftArt_Count">
                <button disabled onClick={() => dispatch(setValue(Count - +1))}>-</button>
                <div className="NftArt_CountOutout">
                  {Count}
                </div>
                <button disabled onClick={() => dispatch(setValue(Count + +1))}>+</button>
              </div>
              <div className="BUSD_Mian">
                {selectedValue && (
                  <button onClick={mint}>
                    {Spinner ? (
                      <>
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </>
                    ) : (
                      <>
                        {selectedValue?.name} - {selectedValue?.price}
                      </>
                    )}
                  </button>
                )}

                <div className="BUSD_text">
                  <Form.Select onChange={handleChange}>
                    {PriceArray?.map((value) => (
                      <>
                        <option value={JSON.stringify(value)} key={value?.name}>
                          {value?.name} - {value?.price}
                        </option>
                      </>
                    ))}
                  </Form.Select>
                </div>
              </div>
              <div className="NftArt_text">
                <h6>NFT Name</h6>
                <h6>{singlenft.title}</h6>
              </div>
              {/* <Button>
                Buy NFT
              </Button> */}
              {/* <div className="NftArt_sharebtn">
                <button>
                  <Image src={share} alt="Image description" fluid={true} />
                  Share this NFT
                </button>
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CollectionNFT;











