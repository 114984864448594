import React from "react";

import { Layout } from "../components";
import NFT_UnStakingHistory from "../components/MintNft/NFT_UnStakingHistory";

const NFT_UnStakingHistoryPage = () => {
  return (
    <>
      <Layout>
        <NFT_UnStakingHistory />
      </Layout>
    </>
  );
};

export default NFT_UnStakingHistoryPage;
