import React from "react";
import { Layout, MainWalletOLDWithdrawal} from "../components";
import "../assets/css/token.css";


const MainWalletOLD = () => {
  return (
    <>
      <Layout>
        <MainWalletOLDWithdrawal />
      </Layout>
    </>
  );
};

export default MainWalletOLD;
