import React from "react";
import { Layout, LARTokenHistory_2 } from "../components";

const LARTokenHistoryPage_2 = () => {
  return (
    <>
      <Layout>
        <LARTokenHistory_2 />
      </Layout>
    </>
  );
};

export default LARTokenHistoryPage_2;
