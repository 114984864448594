import React from "react";
import { Layout } from "../components";
import "../assets/css/token.css";
import LARTokenConverter from "../components/Withdrawal/LARTokenConverter";

const LARTokenToGameWallet = () => {
  return (
    <>
      <Layout>
        <LARTokenConverter/>
      </Layout>
    </>
  );
};

export default LARTokenToGameWallet;
