import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { API } from "../../API/Api";
import { loadWeb3 } from "../../apis/api";
import { LAR_withdrowal, LAR_withdrowal_ABI } from "../../utilies/constant";
import ShareTable from "../sharecomponent/ShareTable";

const columns = [
  {
    dataField: "Number",
    text: "So.No",
    sort: false,
  },
  // {
  //   dataField: "fromUserid",
  //   text: "From Id",
  //   sort: false,
  // },
  {
    dataField: "id",
    text: "Receiver Id",
    sort: false,
  },
  // {
  //     dataField: "coupon",
  //     text: "Coupon No.",
  //     sort: false,
  // },

  {
    dataField: "amount",
    text: "Amount",
    sort: false,
  },
  {
    dataField: "coupon",
    text: "Point",
    sort: false,
  },
  {
    dataField: "DateTime",
    text: "Date & Time",
    sort: false,
  },
];

const LARTokenConverter = () => {
  const [inputValue, setInputValue] = useState({
    amount: "",
    token: "",
    usdamount: "",
    netamount: "",
  });

  const userDetail = useSelector((state) => state.nft.userDetail);
  const user = useSelector((state) => state.UserAuth.userId);
  const [Dash_Board_Array, setDash_Board_Array] = useState("");
  // console.log("userDetail", userDetail);
  const [LAR_live, setLAR_live] = useState("");
  const [get_Value, setget_Value] = useState("");
  const [dataArray, setdataArray] = useState([]);
  const [WalletType, setWalletType] = useState(0);
  const [WType, setWType] = useState(0);
  const [Connect, setConnect] = useState("wallet is not connected..!..Wait...");
  const [spinner, setspinner] = useState(false);
  let [loading, setLoading] = useState(false);

  function handleChange(event) {
    // alert(event.target.value);
    setWalletType(event.target.value);
    if (parseInt(event.target.value) == 0) {
      setWalletType(0);
      setWType("");
    } else if (parseInt(event.target.value) == 1) {
      setWalletType(1);
      setWType("MainWalletOLD");
    } else if (parseInt(event.target.value) == 2) {
      setWalletType(2);
      setWType("MainWalletNEW");
    } else if (parseInt(event.target.value) == 3) {
      setWalletType(3);
      setWType("MainWalletROI");
    }

    //  setSelected(event.target.value);
    //   console.log('Event', event.target.options[event.target.selectedIndex].dataset.key);
  }

  const handleInputChange = async (event) => {
    try {
      console.log("LIve_Raterate", event.target.value);
       event.target.value=event.target.value.replace(/[^0-9]/g, "").substring(0, 8)
      let res = await API.get(`/live_rate_LAR`);
      res = res.data.data[0].usdperunit;
      let value = event.target.value;

      setInputValue({
        ...inputValue,
        [event.target.name]: value,
        token: (1 / res) * value,
      });
      let response = await API.post(`/getDashboardValues_New?id=${user}`);
      console.log("response time", response.data.data);

      response = response.data.data[0];
      setDash_Board_Array(response);
    } catch (e) {
      console.log("Error Whole calling Live Rate API CAlling", e);
    }

    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
  };

  const TotalAmount_API = async () => {
    try {
      let response = await API.post(`/getDashboardValues_New?id=${user}`);
      console.log("TotalAmount_API", response.data.data);

      response = response.data.data[0];
      setDash_Board_Array(response);
    } catch {
      console.log("Somthing error in TotalAmount API");
    }
  };

  const Live_Rate_LAR = async () => {
    try {
      let res = await API.get(`/live_rate_LAR`);
      res = res.data.data[0].usdperunit;
      setInputValue({ ...inputValue, token: (1 / res) * inputValue.usdamount });
      console.log("LIve_Rate", (1 / res) * inputValue.usdamount);
    } catch (e) {
      console.log("Error Whole calling Live Rate API CAlling", e);
    }
  };

  const Connect_Wallet = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setConnect("No Wallet Connected");
        // toast.error("No Wallet Connected")
      } else if (acc == "Wrong Network") {
        setConnect(
          "Wrong Newtwork please connect to Binance smart chain network"
        );

        // toast.error("Wrong Newtwork please connect to Binance smart chain network")
      } else {
        setConnect("Wallet is connected..!");
      }
    } catch (e) {
      console.log("Error While connect Wallet", e);
    }
  };

  const Withdrawal_contract = async () => {
    try {
      setspinner(true);
      let acc = await loadWeb3();
      if (parseFloat(inputValue.usdamount) <= 0) {
        toast.error("Enter Valid Amount");
        setspinner(false);
        return;
      }
      if (acc == "No Wallet") {
        toast.error("No Wallet Connected");
        setspinner(false);
      } else if (acc == "Wrong Network") {
        setspinner(false);
        toast.error(
          "Wrong Newtwork please connect to Binance smart chain network"
        );
      } else {
        console.log("Withdrawal_contract");
        if (parseInt(WalletType) == 1) {
          if (
            parseFloat(Dash_Board_Array.LARNetBalance) <
            parseFloat(inputValue.usdamount)
          ) {
            toast.error("Insufficient Wallet Balance");
            setspinner(false);
            return;
          }
        } else if (parseInt(WalletType) == 2) {
          if (
            parseFloat(Dash_Board_Array.USDTNetBalance) <
            parseFloat(inputValue.usdamount)
          ) {
            toast.error("Insufficient Wallet Balance");
            setspinner(false);
            return;
          }
        } else if (parseInt(WalletType) == 3) {
          if (
            parseFloat(Dash_Board_Array.ROILAGNetBalance) <
            parseFloat(inputValue.usdamount)
          ) {
            toast.error("Insufficient Wallet Balance");
            setspinner(false);
            return;
          }
        } else {
          toast.error("Select Wallet type");
          setspinner(false);
          return;
        }

        if (Dash_Board_Array.address == acc) {
          // save data in db
          let saveResult = await API.post("/AddLarTokenToGameWallet_1", {
            uid: user,
            amount: inputValue.usdamount,
            Wtype: WType,
          });
          saveResult = saveResult.data.data;
          console.log("SUCCESSFUL", saveResult);
          setspinner(false);
          if (saveResult == "Fund Added Successfully.") {
            toast.success(saveResult);
            let response = await API.post(`/getDashboardValues_New?id=${user}`);
            console.log("response time", response.data.data[0]);

            response = response.data.data[0];
            TotalAmount_API();
            // setDash_Board_Array(response);
          } else {
            toast.error(saveResult);
          }
        } else {
          toast.error("Address MissMatch");
          setspinner(false);
        }
      }
    } catch (e) {
      console.log("Error While Calling MultiToken ", e);
      setspinner(false);
    }
  };

  const referral_API = async () => {
    try {
      let responce = await API?.get(
        `/AddLarTokenToGameWalletHistory?id=${user}`
      );
      responce = responce.data.data;
      // setCoupon(responce[0].coupon);
      // setUserName(responce[0].UserName);
      console.log("responceresponce1", responce);

      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item.row,
          //UserName: item?.UserName,
          id: item?.uid,
          amount: item?.amount,
          fromUserid: item?.fromuid,
          coupon: item?.point,
          // Txn: <><a href={`https://bscscan.com/tx/${item?.traxn}`} target="_blank" className='text-white'>View Txn</a></>,
          DateTime: item.dd,
        });
      });
      console.log("responce", arr);

      setdataArray(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
    }
  };

  useEffect(() => {
    referral_API();
    TotalAmount_API();
    // setInterval(() => {
    //   Connect_Wallet()
    // }, 1000);
  }, []);

  useEffect(async () => {
    await Live_Rate_LAR();
  }, []);

  return (
    <>
      <div className="LARToken_Main">
        <div className="LARToken_Header">
          <h6>LAR Token Convert To Game Wallet</h6>
        </div>

        <div className="lar_fromMain">
          <form onSubmit={submitHandler}>
            <div className="lar_inputWrper">
              <label htmlFor="email">Wallet Type</label>
              <select name="walletTypeSelect" onChange={handleChange}>
                <option value="0">Select Wallet</option>
                <option value="1">LAR Token Wallet</option>
                <option value="2">Working USDT Wallet</option>
                <option value="3">Staking Wallet</option>
              </select>
            </div>
            <div className="lar_inputWrper">
              <label htmlFor="WalletAmount">Wallet Net USD Amount</label>
              {WalletType == 1 ? (
                <input
                  type="text"
                  id="WalletAmount"
                  placeholder="Amount"
                  name="amount"
                  value={Dash_Board_Array.LARNetBalance}
                />
              ) : WalletType == 2 ? (
                <input
                  type="text"
                  id="WalletAmount"
                  placeholder="Amount"
                  name="amount"
                  value={Dash_Board_Array.USDTNetBalance}
                />
              ) : WalletType == 3 ? (
                <input
                  type="text"
                  id="WalletAmount"
                  placeholder="Amount"
                  name="amount"
                  value={Dash_Board_Array.ROILAGNetBalance}
                />
              ) : (
                <input
                  type="text"
                  id="WalletAmount"
                  placeholder="Amount"
                  name="amount"
                  value="0"
                />
              )}
            </div>
            <div className="lar_inputWrper">
              <label htmlFor="usdamount">Enter USD Amount</label>
              <input
                type="text"
                placeholder="$"
                name="usdamount"
                defaultValue={inputValue?.usdamount}
                onChange={(e) => handleInputChange(e)}
                maxLength={8}
              />
            </div>
            {/* <div className="lar_inputWrper">
              <label htmlFor="netamount">Net Amount</label>
              <input
                type="number"
                id="netamount"
                placeholder="$"
                name="netamount"
                value={inputValue.netamount}
              // onChange={handleInputChange}
              />
            </div> */}
            <div className="lar_inputWrper">
              <label htmlFor="Withdrawal">Withdrawal Point</label>
              <input
                type="text"
                id="Withdrawal"
                placeholder="Token"
                name="token"
                value={inputValue.usdamount * 90}
                // onChange={handleInputChange}
              />
            </div>

            <div className="lar_button">
              <button onClick={() => Withdrawal_contract()}>
                {spinner ? (
                  <>
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </>
                ) : (
                  "Withdraw"
                )}
              </button>
              <button>Cancel</button>
            </div>
          </form>
        </div>
        <br />
        <br />

        <div className="BgLayout_Header">
          <h6>LAR Token Convert History</h6>
        </div>
        <div className="Share_tableMain">
          <ShareTable columns={columns} Data={dataArray} />
        </div>
      </div>
      <div className="bg_usser_main"></div>
    </>
  );
};

export default LARTokenConverter;
